nav {
  /* background: rgba(0, 0, 0, 0.3); */
  /* width: max-content; */
  display: flex;
  padding: 0.2rem 1.7rem;
  text-align: center;
  justify-content: space-between;
  z-index: 2;
  /* position: fixed; */
  /* right: 50%; */
  /* transform: translateX(-50%); */
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(30px);
}
.right_nav {
  display: flex;
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  /* font-size: 1.1rem; */
}

nav a:hover {
  backdrop-filter: blur(100px);
  /* background: rgba(0, 0, 0, 0.3); */
}

nav a.active {
  /* background: var(--color-bg); */
  color: var(--color-white);
}
.stickynav {
      display: flex;
  backdrop-filter: blur(8px);
    background: rgba(0,0,0,.3);
    border-radius: 3rem;
    bottom: 3rem;
    display: block;
    gap: 0.8rem;
    left: 50%;
    padding: 0.7rem 1.7rem;
    position: fixed;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: -webkit-max-content;
    width: max-content;
    z-index: 2;
        border: 0;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    outline: 0;
    text-decoration: none;
}
.jss1{
  background: transparent;
  border-radius: 15px;
}
.jssb {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 5px;
}
.p1 {
  font-size: x-small;
}
.hidden {
  display: none;
}
.navb p {
  padding-right: 8px;
  background: transparent;
}
