.portfolio__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}
.box {
    position: relative;
    width: 300px;
    transform-style: preserve-3d;
    animation: animate 15s linear infinite;
    scroll-behavior: smooth;
    /* animatio */
}
img {
    image-rendering: auto;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
  
    /* Safari seems to support, but seems deprecated and does the same thing as the others. */
    image-rendering: -webkit-optimize-contrast;
  }
@keyframes animate {
    0%{
        transform:  rotateX(-20deg) rotateY(0deg);
    }
    100%{
        transform: rotateX(-20deg) rotateY(360deg);
    }
}
.box:hover {
    animation-play-state: paused;
}
.box span {
    position: absolute;
    margin-top: -60%;
    /* top: 0; */
    /* left: 0; */
    /* width: 100%; */
    /* height: 200%; */
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i)* 90deg)) rotateX(10deg) translateZ(210px);
    -webkit-box-reflect: below 0px linear-gradient(transparent,transparent,#0002);
    scroll-behavior: smooth;
    
}


.portfolio__item {
    background: #2C4C9C;
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    /* margin-bottom: 1rem; */
}

.portfolio__title{
    text-align: center;
}
.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    /* background: transparent; */
}

.portfolio__item-image {
    border-radius: 0.7rem;
    overflow: hidden;
    /* height: 40%; */
    margin-bottom: 1rem;
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item-cta {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    /* margin-bottom: 1rem;  */
}

@media screen and (max-width: 1400px){
    .portfolio__container {
        min-height: 70vh;
    }
}
@media screen and (max-width: 1024px){
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
        min-height: 60vh;
    }
}
  
@media screen and (max-width: 600px){
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}