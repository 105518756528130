.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 32% 56%;
    /* justify-content: center; */
    /* align-items: center; */
    /* min-height: 100vh; */
    gap: 12%;
} 


.contact__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.2rem;
}

.contact__option {
    /* background: #2C4C9C; */
    margin-top: 10%;
    padding: 5.2rem;
    border-radius: 0.6rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    /* background: transparent; */
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

.contact__option h5 {
    color: var(--color-light);
}

.contact__option h4 {
    font-weight: 500;
}
.linkedin_icon {
    max-width: 14px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    color: #24cedb;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid #24cedb;
    resize: none;
    color: #daa520;    
}
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #daa520;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: pink;
  }
textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #daa520;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: pink;
  }
 
@media screen and (max-width: 1400px){
    .container.contact__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}  

@media screen and (max-width: 1024px){
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 32rem;
    }
    .pyramid {
        margin-top: -8%;
        margin-left: 6%;
    }
}
  
@media screen and (max-width: 600px){
    .container.contact__container {
        width: var(--container-width-sm);
    }
    .pyramid {
        margin-top: -8%;
        margin-left: 16%;
    }
}
.pyramid {
    position: absolute;
    width: 300px;
    height: 300px;
    transform-style: preserve-3d;
    animation: animat 12s linear infinite;
}
@keyframes animat {
    0%{
        transform: rotateX(-30deg) rotateY(0deg);
    }
    100%{
        transform: rotateX(-30deg) rotateY(-360deg);
    }
}
.pyramid div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}
.pyramid div span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#151515, #00ec00);
    /* clip-path: polygon(50%, 0%, 0%, 100%, 100%, 100%); */
    clip-path:polygon(50% 0%,50% -0%,-0% 99%,100% 99%);
    transform: rotateY(calc(90deg * var(--i))) translateZ(150px) rotateX(30deg);
    transform-origin: bottom;
    /* filter: blur(15px); */
}
.pyramid:hover {    
    animation-play-state: paused;
}
.glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00ec00;
    transform: rotateX(90deg) translateZ(-250px);
    filter: blur(20px);
    box-shadow: 0 0 120px rgba(0, 255, 0, 0.2),
    0 0 200px rgba(0, 255, 0, 0.4),
    0 0 300px rgba(0, 255, 0, 0.6),
    0 0 400px rgba(0, 255, 0, 0.8),
    0 0 500px rgba(0, 255, 0, 1);
}